<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body class="mb-0">
          <b-table
            :striped="true"
            :bordered="true"
            :hover="true"
            ref="refUserListTable"
            class="position-relative"
            :items="orders"
            responsive
            :fields="tableColumns"
            primary-key="id"
            sort-by.sync="id"
            show-empty
            empty-text="Kayıt bulunamadı."
            head-variant="dark"
          >
            <template #cell(docNo)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ data.item.docNo }}
              </div>
            </template>
            <template #cell(unit)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ data.item.unit }}
              </div>
            </template>
            <template #cell(orderDate)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ format(new Date(data.item.orderDate)) }}
              </div>
            </template>
            <template #cell(totalPriceFormatted)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.totalPriceFormatted }} {{ data.item.priceUnit }}
              </div>
            </template>
            <template #cell(detail)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  :to="{
                    name: 'myorders-detail',
                    params: { id: data.item.docNo }
                  }"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Toplam sipariş sayısı:<strong> {{ ordersCount }}</strong>
                </span>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="ordersCount"
                  per-page="10"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="changePage(currentPage)"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
  },
  data() {
    return {
      orders: [],
      allOrders: [],
      tableColumns: [
        { key: "docNo", label: "Sipariş Numarası" },
        { key: "unit", label: "Toplam Adet" },
        { key: "addressCode", label: "Şube" },
        { key: "totalPriceFormatted", label: "KDV Dahil Toplam" },
        { key: "orderDate", label: "Sipariş Tarihi" },
        { key: "detail", label: "Detay" }
      ],
      ordersCount: 0,
      currentPage: 1,
      show: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.show = true;
      this.$http
        .get("/User/GetERPOrders")
        .then(response => {
          this.allOrders = response.data.result;
          this.orders = this.allOrders.slice(0, 10);
          this.ordersCount = this.allOrders.length;
          this.show = false;
        })
        .catch(error => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
        
        
    },
    changePage(cPage) {
      const skip = (cPage - 1) * 10;
      const take = skip + 10;
      console.log(skip + "," + take);
      this.orders = this.allOrders.slice(skip, take);
    },
    format(date) {
      var month = date.toLocaleString("tr-TR", { month: "short" });
      return date.getDate() + " " + month + " " + date.getFullYear();
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
